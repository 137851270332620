import React from 'react';
import styles from './careersListing.module.css';
import CareerCard from './CareerCard/CareerCard';
import { Fade } from "react-awesome-reveal";

const CareersListing = () => {
  return <main id='careersListing' className={styles.careersListingContainer}>

      <Fade triggerOnce direction="left">
        <CareerCard 
          category="engineering"
          categoryColor="orange"
          position="React Native Developer"
          location="Gurugram, Haryana"
        />
      </Fade>


      <Fade triggerOnce direction="right" delay={500}>
        <CareerCard 
          category="project management"
          categoryColor="green"
          position="Associate Project Manager"
          location="Gurugram, Haryana"
        />
      </Fade>

      <Fade triggerOnce direction="left" delay={1000}>
        <CareerCard 
          category="human resources / hr"
          categoryColor="green"
          position="Associate Project Manager"
          location="Gurugram, Haryana"
        />
      </Fade>

  </main>;
};

export default CareersListing;

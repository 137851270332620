import React, { useState } from "react";
import styles from "./navbar.module.css";
import cx from "classnames";
import ContactModal from "../../contactUs/contactus";
import logo from "../../../../svgs/logo.svg";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
    setClicked(!clicked);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setClicked(false);
    }
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <nav
      className={
        clicked ? cx(styles.navbar, styles.activeNavbar) : styles.navbar
      }
    >
      <div className={styles.brandNameDiv}>
        <img src={logo} height="80px" alt="Logo" />
        <div className={styles.navMenuIcon} onClick={handleClick}>
          {clicked ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <g stroke-width="1.5" fill="none">
                <path
                  d="M6.758 17.243L12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path
                d="M4 11h12v2H4zm0-5h16v2H4zm0 12h7.235v-2H4z"
                fill="currentColor"
              />
            </svg>
          )}
        </div>
      </div>

      <ul
        className={
          clicked
            ? cx(styles.menuList, styles.active)
            : cx(styles.menuList, styles.hidden)
        }
      >
        <li onClick={() => scrollToSection("home")}>
          <span className={styles.navLink}>Home</span>
        </li>
        <li onClick={() => scrollToSection("service")}>
          <span className={styles.navLink}>Service</span>
        </li>
        <li onClick={() => scrollToSection("project")}>
          <span className={styles.navLink}>Project</span>
        </li>
        <li onClick={() => scrollToSection("technologies")}>
          <span className={styles.navLink}>Technologies</span>
        </li>
        <div className={styles.navb}>
          {" "}
          <div
            onClick={() =>
              window.open(
                "https://calendly.com/demandvirtue/30min?month=2023-11",
                "_blank"
              )
            }
          >
            <button className={styles.consultantbtn}>
              Book Free Consultation
            </button>
          </div>
          <div onClick={() => scrollToSection("contact")}>
            <button className={styles.contactBtn} onClick={openModal}>
              Contact Us
            </button>
          </div>
        </div>
      </ul>
      <div className={styles.navbarB}>
        {" "}
        <div
          onClick={() =>
            window.open(
              "https://calendly.com/demandvirtue/30min?month=2023-11",
              "_blank"
            )
          }
        >
          <button className={styles.consultantbtn}>
            Book Free Consultation
          </button>
        </div>
        <div onClick={() => scrollToSection("contact")}>
          <button className={styles.contactBtn} onClick={openModal}>
            Contact Us
          </button>
        </div>
      </div>

      <ContactModal isOpen={modalIsOpen} onRequestClose={closeModal} />
    </nav>
  );
};

export default Navbar;

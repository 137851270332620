import React from "react";
import styles from "./reviewsFAQs.module.css";
import ReviewsSection from "./ReviewsSection/ReviewsSection";
import FAQsSection from "./FAQsSection/FAQsSection";

const ReviewsFAQs = () => {
  return (
    <section className={styles.reviewsFAQsContainer}>
      <ReviewsSection />
    </section>
  );
};

export default ReviewsFAQs;

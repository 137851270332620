import React from "react";
import { ProductCard } from "./ProductCard/ProductCard";
import styles from "./productsListing.module.css";
import sploot from "../../../svgs/sploot.svg";
import bk from "../../../svgs/bk.svg";
import lxl from "./../../../svgs/lxl.svg";

const ProductsListing = () => {
  function UiUxDesign() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <g fill="none">
          <path
            d="M8.75 3.5A5.25 5.25 0 0 0 8 13.947v1.512A6.751 6.751 0 1 1 15.459 8h-1.512A5.251 5.251 0 0 0 8.75 3.5zm3.5 5.5A3.25 3.25 0 0 0 9 12.25v6.5A3.25 3.25 0 0 0 12.25 22h6.5A3.25 3.25 0 0 0 22 18.75v-6.5A3.25 3.25 0 0 0 18.75 9h-6.5zm-1.75 3.25c0-.966.784-1.75 1.75-1.75h6.5c.966 0 1.75.784 1.75 1.75v6.5a1.75 1.75 0 0 1-1.75 1.75h-6.5a1.75 1.75 0 0 1-1.75-1.75v-6.5z"
            fill="gray"
          />
        </g>
      </svg>
    );
  }
  function AppDevelopment() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16 16"
      >
        <g fill="gray">
          <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
          <path d="M8 14a1 1 0 1 0 0-2a1 1 0 0 0 0 2z" />
        </g>
      </svg>
    );
  }
  function DevOps() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16 16"
      >
        <g fill="gray">
          <path d="M5.68 5.792L7.345 7.75L5.681 9.708a2.75 2.75 0 1 1 0-3.916zM8 6.978L6.416 5.113l-.014-.015a3.75 3.75 0 1 0 0 5.304l.014-.015L8 8.522l1.584 1.865l.014.015a3.75 3.75 0 1 0 0-5.304l-.014.015L8 6.978zm.656.772l1.663-1.958a2.75 2.75 0 1 1 0 3.916L8.656 7.75z" />
        </g>
      </svg>
    );
  }
  function WebDevelopment() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 32 32"
      >
        <path
          d="M5 6v13.563l-2.281 2.314A2.444 2.444 0 0 0 2 23.594A2.418 2.418 0 0 0 4.406 26h23.188A2.418 2.418 0 0 0 30 23.594a2.45 2.45 0 0 0-.719-1.719L27 19.562V6H5zm2 2h18v11H7V8zm9 1l-1.5 9H16l1.5-9H16zm-3.914 2l-1.719 2.068L10 13.5l.367.432L12.086 16l1.086-.863L11.81 13.5l1.36-1.637L12.087 11zm7.828 0l-1.086.863L20.19 13.5l-1.36 1.637l1.085.863l1.719-2.068L22 13.5l-.367-.432L19.914 11zM6.437 21h19.125l2.313 2.281a.464.464 0 0 1 .125.313a.386.386 0 0 1-.406.406H4.406A.386.386 0 0 1 4 23.594c0-.11.047-.234.125-.313L6.438 21z"
          fill="gray"
        />
      </svg>
    );
  }
  function QaTesting() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 1024 1024"
      >
        <path
          fill="gray"
          d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088l-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36l-116.224-25.088l-65.28 113.152l79.68 88.192l-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136l-79.808 88.192l65.344 113.152l116.224-25.024l22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152l24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296l116.288 25.024l65.28-113.152l-79.744-88.192l1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136l79.808-88.128l-65.344-113.152l-116.288 24.96l-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384a192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256a128 128 0 0 0 0-256z"
        />
      </svg>
    );
  }

  const tags1 = [
    { svg: UiUxDesign(), tagName: "UI/UX Design" },
    { svg: AppDevelopment(), tagName: "App Development" },
    { svg: DevOps(), tagName: "DevOps" },
  ];

  const tags2 = [
    { svg: UiUxDesign(), tagName: "UI/UX Design" },
    { svg: AppDevelopment(), tagName: "App Development" },
    // { svg: DevOps(), tagName: "DevOps" },
    { svg: WebDevelopment(), tagName: "Web Development" },
    // { svg: QaTesting(), tagName: "QA / Testing" },
  ];

  const tags3 = [
    { svg: WebDevelopment(), tagName: "Web Development" },
    { svg: UiUxDesign(), tagName: "UI/UX Design" },
    { svg: AppDevelopment(), tagName: "App Development" },
    { svg: DevOps(), tagName: "DevOps" },
  ];
  const handleCardClickbk = () => {
    window.open(
      "https://sridipto.notion.site/Burger-King-India-686a9dcf3cbe483a9337f79be61b75c1",
      "_blank"
    );
  };
  const handleCardClickSploot = () => {
    window.open(
      "https://sridipto.notion.site/Sploot-Pet-Tech-9afe068d78bd41e096c80092c4afbdfa",
      "_blank"
    );
  };
  const handleCardClicklLxl = () => {
    window.open(
      "https://sridipto.notion.site/LXL-Gamified-E-Learning-4192d7b37ad6473c87413a785ab09c51",
      "_blank"
    );
  };
  return (
    <section id="project">
      <h3 className={styles.ourProjetcs}>Our Projects</h3>
      <main className={styles.productListingContainer}>
        <ProductCard
          onClick={handleCardClicklLxl}
          name="LxL - School Cinema"
          desc="End-to-End Gamified Web App Development (with Learning Management System)"
          tags={tags1}
          productCardImg={lxl}
        />

        <ProductCard
          onClick={handleCardClickbk}
          name="Burger King India"
          desc="Boosting Burger King's Engagement through Gamification for Cricket World Cup 2023"
          tags={tags2}
          productCardImg={bk}
        />

        <ProductCard
          onClick={handleCardClickSploot}
          name="Sploot"
          desc="Enhancing Community Engagement for Sploot through Gamification"
          tags={tags3}
          productCardImg={sploot}
        />
      </main>
    </section>
  );
};

export default ProductsListing;

import React from 'react';
import styles from './footer.module.css';
import FooterMainSection from './FooterMainSection/FooterMainSection';
import { Fade } from "react-awesome-reveal";

const Footer = () => {
    return <Fade triggerOnce delay={500}>
 
    <footer className={styles.footerContainer}>

    <div className={styles.backgroundDiv}>&nbsp;</div>
    <FooterMainSection />
    <div className={styles.copyrightSction}>
      <span>&#9400; 2023 Delta•Bit. All rights reserved.</span>
      <pre>Terms of Service   |   Privacy Policy</pre>
    </div>

  </footer>
  </Fade>;
};

export default Footer;

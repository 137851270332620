import "./app.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Service from "./pages/Service/Service";
import Products from "./pages/Products/Products";
import Careers from "./pages/Careers/Careers";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/aboutus" element={<center>About Us</center>} />
        <Route exact path="/blog" element={<center>All Blogs Page</center>} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/careers" element={<Careers />} />
      </Routes>
    </Router>
  );
}

export default App;

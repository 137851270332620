import React from "react";
import styles from "./specificProfit.module.css";

const SpecificProfit = ({ Icon, bgColor, title, desc, imgSrc }) => {
  return (
    <div className={styles.specificProfitContainer}>
      <div className={styles.column}>
        <span style={{ position: "relative" }}>
          {imgSrc ? (
            <div
              className={styles.colorBox}
             
            >
              <img src={imgSrc} alt={title} />
            </div>
          ) : (
            <span
              style={{ backgroundColor: bgColor }}
              className={styles.colorBox}
            >
              <Icon />
            </span>
          )}
        </span>
      </div>

      <div className={styles.column1}>
        <h4>{title}</h4>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default SpecificProfit;

import React from 'react';
import styles from './founderTalk.module.css';
import FounderTalkImg from '../../../svgs/founderTalk.svg';
import { Fade } from "react-awesome-reveal";

const FounderTalk = () => {
  return <article className={styles.founderTalkContainer}>

    <div className={styles.backgroundDiv}>&nbsp;</div>

    <section className={styles.founderTalkMainSection}>

      <Fade triggerOnce delay={500}>
        <div className={styles.founderTalkMainSectionContent}>
          <span className={styles.quoteSvg}>
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M21 3a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455L2 22.5V4a1 1 0 0 1 1-1h18zM10.962 8.1l-.447-.688C8.728 8.187 7.5 9.755 7.5 11.505c0 .995.277 1.609.792 2.156c.324.344.837.589 1.374.589a1.75 1.75 0 0 0 1.75-1.75c0-.92-.711-1.661-1.614-1.745a2.07 2.07 0 0 0-.479.01v-.092c.006-.422.092-1.633 1.454-2.466l.185-.107l-.447-.688zm4.553-.688c-1.787.775-3.015 2.343-3.015 4.093c0 .995.277 1.609.792 2.156c.324.344.837.589 1.374.589a1.75 1.75 0 0 0 1.75-1.75c0-.92-.711-1.661-1.614-1.745a2.07 2.07 0 0 0-.479.01c0-.313-.029-1.762 1.639-2.665z" fill="#2fdd92"/></svg>
          </span>
          <h3>With Experience, Trust is earned.</h3>
          <p className={styles.fonuderQuote}>We help to convert our customers billing challenges into opportunities. With these billing interactions, we gain the trust of all our subscribers. Our busines model's foundation is based on Commitment.</p>
          <div className={styles.founderNameAndCompanyDiv}>
            <span>-</span>
            <div style={{ display: "flex", flexDirection: "column", rowGap: "2px", fontSize: "15px", marginTop: "5px" }}>
              <p style={{ fontWeight: "bold" }}>Sridipto Ghosh</p>
              <p style={{ fontStyle: "italic", color: "gray" }}>CEO & Founder TSO.</p>
            </div>
          </div>
        </div>

        <div className={styles.founderTalkImgDiv}>
          <img src={FounderTalkImg} alt="FounderTalkImg" className={styles.founderTalkImg}/>
        </div>
      </Fade>

    </section>
  </article>;
};

export default FounderTalk;

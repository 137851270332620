import React from 'react';
import styles from './service.module.css';
import ServiceTopbar from '../../components/Service/ServiceTopbar/ServiceTopbar';
import ServicesBasket from '../../components/Service/ServicesBasket/ServicesBasket';
import HowItWorks from '../../components/Service/HowItWorks/HowItWorks';
import FounderTalk from '../../components/Service/FounderTalk/FounderTalk';
import TechnologiesWeUse from '../../components/Service/TechnologoiesWeUse/TechnologiesWeUse';
import Footer from '../../components/Home/Footer/Footer';

const Service = () => {
  return <section className={styles.serviceContainer}>

    <ServiceTopbar />
    <ServicesBasket />
    <HowItWorks />
    <FounderTalk />
    <TechnologiesWeUse />
    <Footer />

  </section>;
};

export default Service;

import React from 'react';
import styles from './products.module.css';
import ProductsTopbar from '../../components/Products/ProductsTopbar/ProductsTopbar';
import ProductsListing from '../../components/Products/ProductsListing/ProductsListing';
import Footer from '../../components/Home/Footer/Footer';

const Products = () => {

  return <section className={styles.productsContainer}>

    <ProductsTopbar />
    <ProductsListing />
    <Footer />

  </section>;
};

export default Products;

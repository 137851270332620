import React from "react";
import styles from "./featuresTextSection.module.css";
import { HashLink } from "react-router-hash-link";

const FeaturesTextSection = ({
  title,
  desc,
  desc1,
  bulletPoints,
  buttonText,
  svgPaths,
  componentsPerColumn = 5,
  onClickHandler,
}) => {
  const totalPoints = bulletPoints ? bulletPoints.length : 0;
  const numberOfColumns =
    componentsPerColumn === 4
      ? 2
      : Math.ceil(totalPoints / componentsPerColumn);
  const perCol = componentsPerColumn === 4 ? 6 : componentsPerColumn;
  return (
    <div className={styles.featuresTextSectionContainer}>
      <h3>{title}</h3>
      <p>{desc}</p>
      <p>{desc1}</p>
      {bulletPoints ? (
        <div
          className={`${styles.columnsContainer} ${
            componentsPerColumn === 4 ? styles.alignInCol : ""
          }`}
        >
          {[...Array(numberOfColumns)].map((_, columnIndex) => (
            <ul key={columnIndex}>
              {bulletPoints
                .slice(columnIndex * perCol, (columnIndex + 1) * perCol)
                .map((point, index) => (
                  <li key={index} className={styles.bulletpoints}>
                    {svgPaths && svgPaths[columnIndex * perCol + index] && (
                      <img
                        className={styles.icon}
                        src={svgPaths[columnIndex * perCol + index]}
                        alt={`Icon ${columnIndex * perCol + index + 1}`}
                      />
                    )}
                    {point}
                  </li>
                ))}
            </ul>
          ))}
        </div>
      ) : null}
      <HashLink smooth to="">
        <button onClick={onClickHandler}>{buttonText}</button>
      </HashLink>
    </div>
  );
};

export default FeaturesTextSection;

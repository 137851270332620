import React, { useState } from "react";
import styles from "./features.module.css";
import FeaturesTextSection from "./FeaturesTextSection/FeaturesTextSection";
import ContactModal from "../../Home/contactUs/contactus";
import FeatureImg1 from "../../../svgs/FeatureImg1.svg";
import FeatureImg2 from "../../../svgs/FeatureImg2.svg";
import FeatureClient from "../../../svgs/featureClient.svg";
import CurvyWaves from "../../../svgs/curvyWaves.svg";
import ecom from "../../../svgs/ecom.svg";
import payment from "../../../svgs/payment.svg";
import admin from "../../../svgs/admin.svg";
import finanace from "../../../svgs/finance.svg";
import agriculture from "../../../svgs/agriculture.svg";
import gamification from "../../../svgs/gamification.svg";
import workflow from "../../../svgs/workflow.svg";
import reale from "../../../svgs/reale.svg";
import media from "../../../svgs/media.svg";
import travel from "../../../svgs/travel.svg";
import saas from "../../../svgs/saas.svg";
import health from "../../../svgs/health.svg";
import sports from "../../../svgs/sports.svg";
import AI from "../../../svgs/ai.svg";
import edu from "../../../svgs/edu.svg";
import iot from "../../../svgs/iot.svg";
import ticket from "../../../svgs/ticketing.svg";
import landing from "../../../svgs/landing.svg";
import AllShapesAttached from "../../../svgs/allShapesAttached.svg";
import FireWorks from "../../../svgs/fireWorks.svg";
import ColoredCurvyWaves from "../../../svgs/coloredCurvyWaves.svg";
import { Fade } from "react-awesome-reveal";

const Features = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const handleProjectButtonContact = () => {
    openModal();
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleButtonClick = () => {
    window.open(
      "https://calendly.com/demandvirtue/30min?month=2023-11",
      "_blank"
    );
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleProjectButtonClick = () => {
    scrollToSection("project");
  };
  return (
    <section id="service" className={styles.featuresContainer}>
      <Fade triggerOnce delay={600}>
        <div className={styles.featureImgAndTextWrapper1}>
          <img
            src={CurvyWaves}
            alt="CurvyWaves"
            className={styles.curvyWavesImg}
          />
          <div className={styles.featureImgWrapper}>
            <img src={FeatureImg1} alt="FeatureImg1" />
          </div>
          <FeaturesTextSection
            title="We Can Help You Build"
            bulletPoints={[
              "E-Commerce",
              "Payment Gateway",
              "Admin Panel",
              "Ticketing System",
              "Landing Page",
              "Gamification",
              "Workflow Management",
              "Health Tech",
              "Edu Tech | LMS & ERP",
              "IoT Systems",
            ]}
            buttonText="Get Started"
            onClickHandler={handleButtonClick}
            componentsPerColumn={5}
            svgPaths={[
              ecom,
              payment,
              admin,
              ticket,
              landing,
              gamification,
              workflow,
              health,
              edu,
              iot,
            ]}
          />

          <img
            src={AllShapesAttached}
            alt="AllShapesAttachedImg"
            className={styles.allShapesAttachedImg}
          />
        </div>
      </Fade>

      <Fade triggerOnce delay={600}>
        <div className={styles.featureImgAndTextWrapper2}>
          <img
            src={FireWorks}
            alt="FireWorksImg"
            className={styles.fireWorksImg}
          />
          <FeaturesTextSection
            title="Across Sectors Like"
            bulletPoints={[
              "E-Commerce",
              "Healthcare",
              "Agriculture",
              "Education",
              "Finanace",
              "Travel",
              "Media",
              "SaaS",
              "Sports",
              "Gaming",
              "AI/ML",
              "RealEstate",
            ]}
            buttonText="Get Started"
            onClickHandler={handleProjectButtonContact}
            componentsPerColumn={4}
            svgPaths={[
              ecom,
              health,
              agriculture,
              edu,
              finanace,
              travel,
              media,
              saas,
              sports,
              gamification,
              AI,
              reale,
            ]}
          />
          <div className={styles.featureImg2Wrapper}>
            <img src={FeatureImg2} alt="FeatureImg2" />
          </div>
        </div>
      </Fade>
      <Fade triggerOnce delay={600}>
        <div className={styles.featureImgAndTextWrapper3}>
          <img
            src={ColoredCurvyWaves}
            alt="ColoredCurvyWavesImg"
            className={styles.coloredCurvyWavesImg}
          />

          <div className={styles.featureImgWrapper2}>
            <img src={FeatureClient} alt="FeatureImg3" />
          </div>
          <FeaturesTextSection
            title="Our Clients"
            desc="At ∂etlaBiT we strive hard to unravel solutions and build tech to meet new challenges across industries, company life stages and sectors.

         "
            desc1=" From young startups building their MVPs, to enterprises seeking to upgrade their ERP systems, we have partnered with all."
            buttonText="See Our Work"
            onClickHandler={handleProjectButtonClick}
          />
        </div>
      </Fade>
      <ContactModal isOpen={modalIsOpen} onRequestClose={closeModal} />
    </section>
  );
};

export default Features;

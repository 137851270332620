import React, { useEffect, useState } from "react";
import styles from "./servicesProfits.module.css";
import SpecificProfit from "./SpecificProfit/SpecificProfit";
import AllShapesAttached from "../../../../svgs/allShapesAttached.svg";
import project from "../../../../svgs/project.png";
import earth from "../../../../svgs/earth.png";
import industry from "../../../../svgs/industry.png";
import { AttentionSeeker } from "react-awesome-reveal";

const ServicesProfits = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 650);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 650);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className={styles.servicesProfitsContainer}>
      <div className={styles.servicesProfitsSection1}>
        <h3>
          Focus on Your Business, Leave Technology To Us
        </h3>
        <p>
          ∂etlaBiT is a progressive and insightful design thinking and
          gamification led technology partner to help you transform your brand
          into a digital powerhouse.
        </p>{" "}
      </div>

      <div className={styles.servicesProfitsSection2}>
        {isSmallScreen ? (
          <AttentionSeeker triggerOnce cascade bounce damping={0.5}>
            <div className={styles.specificProfitWrapper}>
              <SpecificProfit imgSrc={project} />

              <SpecificProfit imgSrc={industry} />

              <SpecificProfit imgSrc={earth} />
            </div>
          </AttentionSeeker>
        ) : (
          <AttentionSeeker triggerOnce cascade bounce damping={0.5}>
            <SpecificProfit imgSrc={project} />

            <SpecificProfit imgSrc={industry} />

            <SpecificProfit imgSrc={earth} />
          </AttentionSeeker>
        )}

        <img
          src={AllShapesAttached}
          alt="ShapesImg"
          className={styles.allShapesAttachedImg}
        />
      </div>
    </section>
  );
};

export default ServicesProfits;

import React from "react";
import Navbar from "./Navbar/Navbar";
import styles from "./topbar.module.css";
import MainImg from "../../../svgs/mainImg.svg";
import CurvyWaves from "../../../svgs/curvyWaves.svg";
import SquareCircle from "../../../svgs/squareCircle.svg";
import { AttentionSeeker } from "react-awesome-reveal";
import { HashLink } from "react-router-hash-link";

const Topbar = () => {
  return (
    <header className={styles.topbarContainer}>
      <Navbar />

      <div className={styles.topbarContentContainer}>
        <main className={styles.topbarMainSection}>
          <h1>
            We Help Bring Ideas to{" "}
            <span className={styles.lifeText}>Life</span> with
            <span className={styles.techText}>Technology</span>{" "}
          </h1>
          
          <p>
            Whether it’s a high-converting website, a novel software or a
            revolutionary app, we help your business build from
            <span className={styles.mvpText}>MVP to MDP,</span>
            <span className={styles.lifeText}>bit-by-bit.</span>
          </p>
          <AttentionSeeker effect="shakeX" triggerOnce>
            <HashLink to="">
              <button
                onClick={() =>
                  window.open(
                    "https://calendly.com/demandvirtue/30min?month=2023-11",
                    "_blank"
                  )
                }
              >
                Start Building
              </button>
            </HashLink>
          </AttentionSeeker>
        </main>

        <aside className={styles.topbarImgSection}>
          <img src={MainImg} alt="MainImg" className={styles.topbarImg} />
        </aside>
      </div>

      <div className={styles.iconContainer}>
        <img
          src={CurvyWaves}
          alt="CurvyWaves"
          className={styles.curvyWavesImg}
        />
        <img
          src={SquareCircle}
          alt="SquareCircle"
          className={styles.squareCircleImg}
        />
      </div>
    </header>
  );
};

export default Topbar;

import React from 'react';
import styles from './careers.module.css';
import CareersTopbar from '../../components/Careers/CareersTopbar/CareersTopbar';
import CareersListing from '../../components/Careers/CareersListing/CareersListing';
import Footer from '../../components/Home/Footer/Footer';

const Careers = () => {
  return <section className={styles.careersContainer}>

      <CareersTopbar />

      <CareersListing />

      <Footer />

  </section>;
};

export default Careers;

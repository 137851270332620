import React from 'react';
import styles from './specificServiceBasket.module.css';

const SpecificServiceBasket = ({ Icon, title, desc }) => {
  return <div className={styles.specificServiceBasketContainer}>

    <span className={styles.iconContainer}>
        <Icon />
    </span>

    <h4>{title}</h4>

    <p>{desc}</p>

    {/* <span>
        <svg style={{ fontSize: "30px", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M17 8l4 4m0 0l-4 4m4-4H3" stroke="gray" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
    </span> */}

  </div>;
};

export default SpecificServiceBasket;

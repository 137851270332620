import React from 'react';
import styles from './servicesBasket.module.css';
import SpecificServiceBasket from './SpecificServiceBasket/SpecificServiceBasket';
import SquareEdgeImg from '../../../svgs/squareEdge.svg';
import FireWorksImg from '../../../svgs/fireWorks.svg';
import { Bounce } from "react-awesome-reveal";

const ServicesBasket = () => {

  function MySvgIcon1() {
    return (<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.25em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512"><path d="M255.03 261.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L253.25 192l35.71-35.72c6.25-6.25 6.25-16.38 0-22.63l-11.31-11.31c-6.25-6.25-16.38-6.25-22.63 0l-58.34 58.34c-6.25 6.25-6.25 16.38 0 22.63l58.35 58.34zm96.01-11.3l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0l58.34-58.34c6.25-6.25 6.25-16.38 0-22.63l-58.34-58.34c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63L386.75 192l-35.71 35.72c-6.25 6.25-6.25 16.38 0 22.63zM624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z" fill="#6495ED"/></svg>);
  }

  function codeIcon(){
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1em" viewBox="0 0 24 24" fill="none" stroke="#6495ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline> </svg>);
  }
  function moneyIcon(){
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1em" viewBox="0 0 24 24" fill="none" stroke="#6495ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path> </svg>);
  }
  function analyticsIcon(){
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1em" viewBox="0 0 24 24" fill="none" stroke="#6495ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path> </svg>);
  }
  function mobileIcon(){
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1em" viewBox="0 0 24 24" fill="none" stroke="#6495ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12.01" y2="18"></line> </svg>);
  }
  function growthIcon(){
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1em" viewBox="0 0 24 24" fill="none" stroke="#6495ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20.2 7.8l-7.7 7.7-4-4-5.7 5.7"/><path d="M15 7h6v6"/> </svg>);
  }
  function uiIcon(){
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.25em" height="1em" viewBox="0 0 24 24" fill="none" stroke="#6495ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="14 2 18 6 7 17 3 17 3 13 14 2"></polygon><line x1="3" y1="22" x2="21" y2="22"></line></svg>);
  }



  return <main className={styles.servicesBasketContainer}>

    <section className={styles.servicesBasketTitleSection}>
      <span>Our services</span>
      <h3>Our Product Basket Comprises</h3>
    </section>

    <section className={styles.servicesBasketContentSection}>
      <div className={styles.backgroundDiv}>
        <img src={FireWorksImg} alt="FireWorksImg" className={styles.fireWorksImg}/>
      </div>
      <div className={styles.contentSection}>

        <Bounce triggerOnce direction='down' duration={1200} delay={1300}>
          <div className={styles.contentSection1}>
            <SpecificServiceBasket 
              Icon={analyticsIcon}
              title="Business Development"
              desc="We focus on giving best payment and the most convientent payment options."
            />
          </div>
        </Bounce>

        <Bounce triggerOnce direction='down' duration={1200} delay={1000}>
          <div className={styles.contentSection2}>
            <SpecificServiceBasket 
              Icon={codeIcon}
              title="Web Development"
              desc="We focus on giving best payment and the most convientent payment options."
            />
            <SpecificServiceBasket 
              Icon={mobileIcon}
              title="Mobile Applications"
              desc="We focus on giving best payment and the most convientent payment options."
            />
          </div>
        </Bounce>

        <Bounce triggerOnce direction='down' duration={1200} delay={500}>
          <div className={styles.contentSection3}>
            <img src={SquareEdgeImg} alt="SquareEdgeImg" className={styles.squareEdgeImg}/>
            <SpecificServiceBasket 
              Icon={uiIcon}
              title="UI/UX and Graphics"
              desc="We focus on giving best payment and the most convientent payment options."
            />
            <SpecificServiceBasket 
              Icon={moneyIcon}
              title="Payment Services"
              desc="We focus on giving best payment and the most convientent payment options."
            />
            <SpecificServiceBasket 
              Icon={growthIcon}
              title="Digital Marketing"
              desc="We focus on giving best payment and the most convientent payment options."
            />
          </div>
        </Bounce>
      </div>
    </section>
  </main>;
};

export default ServicesBasket;

import React from "react";
import styles from "./specificService.module.css";

const SpecificService = ({ Icon, bgColor, title, desc, imgSrc }) => {
  return (
    <div className={styles.specificServiceContainer}>
      {imgSrc ? (
        <div style={{ backgroundColor: bgColor }} className={styles.iconBox}>
          <img src={imgSrc} alt={title} />
        </div>
      ) : (
        <span style={{ backgroundColor: bgColor }} className={styles.iconBox}>
          <Icon />
        </span>
      )}
      <h4>{title}</h4>

      <p>{desc}</p>
    </div>
  );
};

export default SpecificService;

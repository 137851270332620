import React from 'react';
import styles from './serviceTopbar.module.css';
import Navbar from '../../../components/Home/Topbar/Navbar/Navbar';
import ServiceMainImg from '../../../svgs/serviceMainImg.svg';
import { Bounce } from "react-awesome-reveal";

const ServiceTopbar = () => {
  return <header id='serviceTop' className={styles.serviceTopbarContainer}>

    <Navbar />

    <section className={styles.serviceTopbarContentContainer}>
      <div className={styles.serviceTopbarMainSection}>
        <h1>Unfolding the Power of Subscription Economy.</h1>
        <p>We focus on Optimizing your Subscriptions along with increasing your Recurring Revenue.</p>
        <Bounce triggerOnce direction="left">
          <div className={styles.inputDiv}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10c1.466 0 2.961-.371 4.442-1.104l-.885-1.793C14.353 19.698 13.156 20 12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8s8 3.589 8 8v1c0 .692-.313 2-1.5 2c-1.396 0-1.494-1.819-1.5-2V8h-2v.025A4.954 4.954 0 0 0 12 7c-2.757 0-5 2.243-5 5s2.243 5 5 5c1.45 0 2.748-.631 3.662-1.621c.524.89 1.408 1.621 2.838 1.621c2.273 0 3.5-2.061 3.5-4v-1c0-5.514-4.486-10-10-10zm0 13c-1.654 0-3-1.346-3-3s1.346-3 3-3s3 1.346 3 3s-1.346 3-3 3z" fill="gray"/></svg>
              </span>
              <input
                type="text"
                name="password"
                placeholder="Enter your Email..."
                className={styles.inputField}
              />
            </div>
            
          </div>
        </Bounce>
        <span className={styles.requestDemoSpan}>
          Want to see some example? <span style={{ textDecoration: "underline", cursor: "pointer", color: "black" }}>Request Demo</span>
        </span>

      </div>

      <aside className={styles.serviceTopbarImgSection}>
        <img src={ServiceMainImg} alt="ServiceMainImg" className={styles.serviceTopbarImg} />
      </aside>
    </section>

  </header>;
};

export default ServiceTopbar;

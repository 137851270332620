import React from "react";
import styles from "./servicesDescription.module.css";
import SpecificService from "./SpecificService/SpecificService";
import WireImg from "../../../../svgs/wire.svg";
import svgIcon1 from "../../../../svgs/svgIcon1.svg";
import web from "../../../../svgs/web.svg";
import app from "../../../../svgs/app.svg";
import devops from "../../../../svgs/devops.svg";

import { Fade } from "react-awesome-reveal";
import { HashLink } from "react-router-hash-link";

const ServicesDescription = () => {
  return (
    <section className={styles.servicesDescriptionContainer}>
      <Fade triggerOnce direction="left" duration={2000}>
        <main className={styles.specificServiceWrapper}>
          <SpecificService
            imgSrc={svgIcon1}
            title="UI/UX Design"
            desc="Responsive, Human-Focused Designs and User Experience"
          />
          <SpecificService
            imgSrc={web}
            title="Web Development"
            desc="Beautiful, Responsive, and Fast-Converting Websites"
          />

          <SpecificService
            imgSrc={app}
            title="App Development"
            desc="Android, iOS and Web-Apps for Multiple Devices, Screen Sizes, and Resolutions"
          />

          <SpecificService
            imgSrc={devops}
            title="DevOps"
            desc="Products Capable of Handling Users at Scale - Beyond 100M+ Users Monthly"
          />
        </main>
      </Fade>

      <Fade triggerOnce direction="right" duration={2000}>
        <aside className={styles.servicesDescriptionAsideSection}>
          <h3>Launch Faster, Scale Better</h3>

          <p>
            ∂etlaBiT helps you build Complex, Scalable, and Reliable tech
            products across Industries to that solve real customer problems.
          </p>

          <HashLink smooth to="">
            <button
              onClick={() =>
                window.open(
                  "https://calendly.com/demandvirtue/30min?month=2023-11",
                  "_blank"
                )
              }
            >
              Talk To Us
            </button>
          </HashLink>

          <img src={WireImg} alt="WireImg" className={styles.wireImg} />
        </aside>
      </Fade>
    </section>
  );
};

export default ServicesDescription;

import React from 'react';
import styles from './howItWorks.module.css';
import HowItWorksImg1 from '../../../svgs/servicePageHow1.svg';
import HowItWorksImg2 from '../../../svgs/servicePageHow2.svg';
import HowItWorksImg3 from '../../../svgs/servicePageHow3.svg';
import { Bounce, AttentionSeeker } from "react-awesome-reveal";

const HowItWorks = () => {
  return <article id='howItWorks' className={styles.howItWorksContainer}>

    <Bounce triggerOnce direction="right" delay={500}>
      <section className={styles.howItWorksTitleSection}>
        <h3>How it works</h3>
        <p>We focus on our subscribers as we know everything else will follow</p>
      </section>
    </Bounce>

    <section className={styles.howItWorksMainSection}>

      <div className={styles.specificHowItWorks}>
        <div className={styles.specificHowItWorksImg}>
          <img src={HowItWorksImg1} alt="HowItWorksImg1" className='howItWorksImg1'/>
        </div>
        <div className={styles.specificHowItWorksContent}>
          <AttentionSeeker effect='headShake' delay={500}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M21 11h-3.94a.78.78 0 0 0-.21 0h-.17a1.3 1.3 0 0 0-.15.1a1.67 1.67 0 0 0-.16.12a1 1 0 0 0-.09.13a1.32 1.32 0 0 0-.12.2l-1.6 4.41l-4.17-11.3a1 1 0 0 0-1.88 0L6.2 11H3a1 1 0 0 0 0 2H7.3a.86.86 0 0 0 .16-.1a1.67 1.67 0 0 0 .16-.12l.09-.13a1 1 0 0 0 .12-.2l1.62-4.53l4.16 11.42a1 1 0 0 0 .94.66a1 1 0 0 0 .94-.66l2.3-6.34H21a1 1 0 0 0 0-2z" fill="#f94892"/></svg>
            </span>
          </AttentionSeeker>
          <h4>Built, Launch, Excel and Grow.</h4>
          <p>Self-service portals create business opportunities to engage your subscribers by creating add-ons. Once your business starts to grow your business cycle will become recurring revenue.</p>
        </div>
      </div>

      <div className={styles.specificHowItWorks}>
        <div className={styles.specificHowItWorksContent}>
          <AttentionSeeker effect='headShake' delay={500}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="#71DFE7"><path d="M16 7.992C16 3.58 12.416 0 8 0S0 3.58 0 7.992c0 2.43 1.104 4.62 2.832 6.09c.016.016.032.016.032.032c.144.112.288.224.448.336c.08.048.144.111.224.175A7.98 7.98 0 0 0 8.016 16a7.98 7.98 0 0 0 4.48-1.375c.08-.048.144-.111.224-.16c.144-.111.304-.223.448-.335c.016-.016.032-.016.032-.032c1.696-1.487 2.8-3.676 2.8-6.106zm-8 7.001c-1.504 0-2.88-.48-4.016-1.279c.016-.128.048-.255.08-.383a4.17 4.17 0 0 1 .416-.991c.176-.304.384-.576.64-.816c.24-.24.528-.463.816-.639c.304-.176.624-.304.976-.4A4.15 4.15 0 0 1 8 10.342a4.185 4.185 0 0 1 2.928 1.166c.368.368.656.8.864 1.295c.112.288.192.592.24.911A7.03 7.03 0 0 1 8 14.993zm-2.448-7.4a2.49 2.49 0 0 1-.208-1.024c0-.351.064-.703.208-1.023c.144-.32.336-.607.576-.847c.24-.24.528-.431.848-.575c.32-.144.672-.208 1.024-.208c.368 0 .704.064 1.024.208c.32.144.608.336.848.575c.24.24.432.528.576.847c.144.32.208.672.208 1.023c0 .368-.064.704-.208 1.023a2.84 2.84 0 0 1-.576.848a2.84 2.84 0 0 1-.848.575a2.715 2.715 0 0 1-2.064 0a2.84 2.84 0 0 1-.848-.575a2.526 2.526 0 0 1-.56-.848zm7.424 5.306c0-.032-.016-.048-.016-.08a5.22 5.22 0 0 0-.688-1.406a4.883 4.883 0 0 0-1.088-1.135a5.207 5.207 0 0 0-1.04-.608a2.82 2.82 0 0 0 .464-.383a4.2 4.2 0 0 0 .624-.784a3.624 3.624 0 0 0 .528-1.934a3.71 3.71 0 0 0-.288-1.47a3.799 3.799 0 0 0-.816-1.199a3.845 3.845 0 0 0-1.2-.8a3.72 3.72 0 0 0-1.472-.287a3.72 3.72 0 0 0-1.472.288a3.631 3.631 0 0 0-1.2.815a3.84 3.84 0 0 0-.8 1.199a3.71 3.71 0 0 0-.288 1.47c0 .352.048.688.144 1.007c.096.336.224.64.4.927c.16.288.384.544.624.784c.144.144.304.271.48.383a5.12 5.12 0 0 0-1.04.624c-.416.32-.784.703-1.088 1.119a4.999 4.999 0 0 0-.688 1.406c-.016.032-.016.064-.016.08C1.776 11.636.992 9.91.992 7.992C.992 4.14 4.144.991 8 .991s7.008 3.149 7.008 7.001a6.96 6.96 0 0 1-2.032 4.907z"/></g></svg>
            </span>
          </AttentionSeeker>
          <h4>Customer Retention Management.</h4>
          <p>Managing your customer retention rate is an incredibly important part of growing your subscription business. Provide your customers with flexible tools and different pricing options.</p>
        </div>
        <div className={styles.specificHowItWorksImg}>
          <img src={HowItWorksImg2} alt="HowItWorksImg2" className='howItWorksImg2'/>
        </div>
      </div>

      <div className={styles.specificHowItWorks}>
        <div className={styles.specificHowItWorksImg}>
          <img src={HowItWorksImg3} alt="HowItWorksImg3" className='howItWorksImg3'/>
        </div>
        <div className={styles.specificHowItWorksContent}>
          <AttentionSeeker effect='headShake' delay={500}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"><path d="M128 12c-43.3 0-76 19.8-76 46v140c0 26.2 32.7 46 76 46s76-19.8 76-46V58c0-26.2-32.7-46-76-46zm0 24c31.7 0 52 13 52 22s-20.3 22-52 22s-52-13-52-22s20.3-22 52-22zm0 184c-31.7 0-52-13-52-22V92.1c13.4 7.5 31.5 11.9 52 11.9s38.6-4.4 52-11.9V198c0 9-20.3 22-52 22z" fill="#ffad60"/></svg>
            </span>
          </AttentionSeeker>
          <h4>Create a Data Secured System.</h4>
          <p>Your billing platform should be with a PCI-Compliant Checkout, and accurate audit trails with GAAPcompliant Recurring Revenue Recognition ensure your customer's data to be secured.</p>
        </div>
      </div>

    </section>

  </article>;
};

export default HowItWorks;

import React, { useState } from "react";
import styles from "./footerMainSection.module.css";
import StarImg from "../../../../svgs/stars/3.svg";
import ColoredCurvyWaves from "../../../../svgs/coloredCurvyWaves.svg";
import FireWorks from "../../../../svgs/fireWorks2.svg";
import AllShapesAttached from "../../../../svgs/allShapesAttached.svg";
import logo from "../../../../svgs/logo.svg";
import ContactModal from "../../contactUs/contactus";

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

const FooterMainSection = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const handleProjectButtonContact = () => {
    openModal();
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <section className={styles.footerMainSectionContainer}>
        <img src={StarImg} alt="StarImg" className={styles.starImg1} />
        <img src={StarImg} alt="StarImg" className={styles.starImg2} />
        <img
          src={ColoredCurvyWaves}
          alt="ColoredCurvyWavesImg"
          className={styles.coloredCurvyWaves}
        />
        <img src={FireWorks} alt="FireWorksImg" className={styles.fireWorks} />
        <img
          src={AllShapesAttached}
          alt="AllShapesAttachedImg"
          className={styles.allShapesAttached}
        />

        <div className={styles.footerUpperSectionContainer}>
          <div style={{ textAlign: "center" }}>
            <h3>Have an awesome idea?</h3>
            <h3>Tell us about it.</h3>
          </div>
          <button onClick={handleProjectButtonContact}>Let's Talk</button>
        </div>

        <nav className={styles.footerNavbarSectionContainer}>
          <div className={styles.footerNavMenu1}>
            <img src={logo} alt="alt" height="100px" />
            <p>Your Partners in creating and growing Brands</p>
            <div className={styles.socialContainer}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <g fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.465 1.066C8.638 1.012 9.012 1 12 1c2.988 0 3.362.013 4.534.066c1.172.053 1.972.24 2.672.511c.733.277 1.398.71 1.948 1.27c.56.549.992 1.213 1.268 1.947c.272.7.458 1.5.512 2.67C22.988 8.639 23 9.013 23 12c0 2.988-.013 3.362-.066 4.535c-.053 1.17-.24 1.97-.512 2.67a5.396 5.396 0 0 1-1.268 1.949c-.55.56-1.215.992-1.948 1.268c-.7.272-1.5.458-2.67.512c-1.174.054-1.548.066-4.536.066c-2.988 0-3.362-.013-4.535-.066c-1.17-.053-1.97-.24-2.67-.512a5.397 5.397 0 0 1-1.949-1.268a5.392 5.392 0 0 1-1.269-1.948c-.271-.7-.457-1.5-.511-2.67C1.012 15.361 1 14.987 1 12c0-2.988.013-3.362.066-4.534c.053-1.172.24-1.972.511-2.672a5.396 5.396 0 0 1 1.27-1.948a5.392 5.392 0 0 1 1.947-1.269c.7-.271 1.5-.457 2.67-.511zm8.98 1.98c-1.16-.053-1.508-.064-4.445-.064c-2.937 0-3.285.011-4.445.064c-1.073.049-1.655.228-2.043.379c-.513.2-.88.437-1.265.822a3.412 3.412 0 0 0-.822 1.265c-.151.388-.33.97-.379 2.043c-.053 1.16-.064 1.508-.064 4.445c0 2.937.011 3.285.064 4.445c.049 1.073.228 1.655.379 2.043c.176.477.457.91.822 1.265c.355.365.788.646 1.265.822c.388.151.97.33 2.043.379c1.16.053 1.507.064 4.445.064c2.938 0 3.285-.011 4.445-.064c1.073-.049 1.655-.228 2.043-.379c.513-.2.88-.437 1.265-.822c.365-.355.646-.788.822-1.265c.151-.388.33-.97.379-2.043c.053-1.16.064-1.508.064-4.445c0-2.937-.011-3.285-.064-4.445c-.049-1.073-.228-1.655-.379-2.043c-.2-.513-.437-.88-.822-1.265a3.413 3.413 0 0 0-1.265-.822c-.388-.151-.97-.33-2.043-.379zm-5.85 12.345a3.669 3.669 0 0 0 4-5.986a3.67 3.67 0 1 0-4 5.986zM8.002 8.002a5.654 5.654 0 1 1 7.996 7.996a5.654 5.654 0 0 1-7.996-7.996zm10.906-.814a1.337 1.337 0 1 0-1.89-1.89a1.337 1.337 0 0 0 1.89 1.89z"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <g fill="none">
                    <path
                      d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003z"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <circle cx="4.983" cy="5.009" r="2.188" fill="currentColor" />
                  <path
                    d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118c1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783c-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className={styles.footerNavMenu2}>
            <div className={styles.footerNavMenu2Div}>
              <h6>Support</h6>
              <span>Pricing</span>
              <span>Partners</span>
              <span>Portfolio</span>
            </div>

            <div className={styles.footerNavMenu2Div}>
              <h6>Service</h6>
              <span onClick={() => scrollToSection("technologies")}>
                Web Design
              </span>
              <span onClick={() => scrollToSection("technologies")}>
                Mobile App
              </span>
              <span onClick={() => scrollToSection("technologies")}>
                Dashboard Design
              </span>
            </div>
          </div>

          <div className={styles.footerNavMenu3}>
            <div className={styles.footerNavMenu3Div}>
              <h6>Contact Us</h6>
              <div className={styles.infoContainer}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none">
                      <path
                        d="M18.997 20C10.466 20.012 3.991 13.46 4 5.003C4 4.45 4.448 4 5 4h2.64c.495 0 .916.364.989.854a12.417 12.417 0 0 0 1.015 3.397l.103.222a.707.707 0 0 1-.23.872c-.818.584-1.13 1.759-.493 2.675c.799 1.15 1.806 2.158 2.956 2.957c.917.636 2.092.324 2.675-.493a.707.707 0 0 1 .873-.231l.221.102c1.078.5 2.222.842 3.397 1.016c.49.073.854.494.854.99V19a1 1 0 0 1-1.001 1h-.002z"
                        fill="currentColor"
                      />
                    </g>
                  </svg>
                </span>
                <p>+91 98368 86775</p>
              </div>
              <div className={styles.infoContainer}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none">
                      <path
                        d="M20 20H4a2 2 0 0 1-2-2V5.913A2 2 0 0 1 4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2zM4 7.868V18h16V7.868L12 13.2L4 7.868zM4.8 6l7.2 4.8L19.2 6H4.8z"
                        fill="currentColor"
                      />
                    </g>
                  </svg>
                </span>
                <p>contact@snipeit.ai</p>
              </div>
            </div>
          </div>
        </nav>
      </section>
      <ContactModal isOpen={modalIsOpen} onRequestClose={closeModal} />
    </>
  );
};

export default FooterMainSection;

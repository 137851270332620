import React from "react";
import styles from "./home.module.css";
import Topbar from "../../components/Home/Topbar/Topbar";
import Services from "../../components/Home/Services/Services";
import Features from "../../components/Home/Features/Features";
import Footer from "../../components/Home/Footer/Footer";
import TechnologiesWeUse from "../../components/Service/TechnologoiesWeUse/TechnologiesWeUse";
import ProductsListing from "../../components/Products/ProductsListing/ProductsListing";
import ReviewsFAQs from "../../components/Home/ReviewsFAQs/ReviewsFAQs";

const Home = () => {
  return (
    <section className={styles.homeContainer}>
      <Topbar />
      <Services />
      <Features />
      <ReviewsFAQs />
      <TechnologiesWeUse />
      <ProductsListing />
      <Footer />
   
    </section>
  );
};

export default Home;

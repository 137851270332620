import React from 'react';
import Navbar from '../../Home/Topbar/Navbar/Navbar';
import styles from './productsTopbar.module.css';
import ProductsHeaderImg from '../../../svgs/productsHeader.svg';
import { Fade } from "react-awesome-reveal";

const ProductsTopbar = () => {
  return <header id='productsTop' className={styles.productsTopbarContainer}>

    <Navbar />

    <section className={styles.productsTopbarContentContainer}>

      <div className={styles.productsTopbarMainSection}>
        <Fade triggerOnce direction="left" duration={1000}>
          <h1>Improve patients' engagement and outcomes</h1>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Saepe neque assumenda nostrum nam sed unde aperiam quo? Eaque facilis nam animi quibusdam.</p>
          <div className={styles.productsTopbarBtnSection}>
            <button style={{ color: "white", backgroundColor: "black" }} className={styles.productTopbarBtn}>Sign up for free</button>  
            <button style={{ color: "black", backgroundColor: "#E4E4E4" }} className={styles.productTopbarBtn}>See how it works</button>          
          </div>
        </Fade>
      </div>

      <aside className={styles.productsTopbarImgSection}>
        <Fade triggerOnce direction="right" duration={1000}>
            <img src={ProductsHeaderImg} alt="ProductsHeaderImg" className={styles.productsHeaderImg} />
        </Fade>
      </aside>

    </section>


  </header>;
};

export default ProductsTopbar;

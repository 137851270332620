import React from "react";
import styles from "./productCard.module.css";

export const ProductCard = ({ name, desc, tags, onClick , productCardImg}) => {
  return (
    <div className={styles.productCardContainer} onClick={onClick}>
      <div className={styles.productCardImgSection}>
        <img
          src={productCardImg}
          alt="ProductCardImg"
          className={styles.productCardImg}
        />
      </div>

      <div className={styles.productCardContentSection}>
        <div className={styles.nameAndDescSection}>
          <h3>{name}</h3>
          <p>{desc}</p>
        </div>
        <div className={styles.tagsSection}>
          {tags.map((tag, id) => {
            return (
              <div className={styles.specificTag} key={id}>
                <span className={styles.svgContainer}>{tag.svg}</span>
                <span className={styles.tagName}>{tag.tagName}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

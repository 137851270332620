import React from 'react';
import styles from './careersTopbar.module.css';
import Navbar from '../../Home/Topbar/Navbar/Navbar';
import { HashLink } from 'react-router-hash-link';
import { Bounce } from "react-awesome-reveal";

const CareersTopbar = () => {
  return <section id='careersTop' className={styles.careersTopbarContainer}>

    <Navbar />

      <div className={styles.careersTopbarContentContainer}>
        <h1>Join our Team.</h1>
        <p>Help us on our quest to make good software even better</p>
        <Bounce triggerOnce direction='down'>
        <HashLink smooth to="#careersListing"><button>See current openings</button></HashLink>
        </Bounce>
      </div>

  </section>;
};

export default CareersTopbar;

import React from "react";
import Slider from "react-slick";
import styles from "./reviewsSection.module.css";
import ReviewImg1 from "../../../../svgs/reviewImage2.svg";
import ReviewImg2 from "../../../../svgs/reviewimage3.svg";

import Star1 from "../../../../svgs/stars/1.svg";
import Star2 from "../../../../svgs/stars/2.svg";
import Star3 from "../../../../svgs/stars/3.svg";
import Profile1 from "../../../../svgs/profiles/1.svg";
import Profile2 from "../../../../svgs/profiles/2.svg";
import Profile3 from "../../../../svgs/profiles/3.svg";
import Profile4 from "../../../../svgs/profiles/4.svg";
import Profile5 from "../../../../svgs/profiles/5.svg";
import Profile6 from "../../../../svgs/profiles/6.svg";
import { Bounce } from "react-awesome-reveal";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ReviewsSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  const reviewsData = [
    {
      clientName: "Abhishek Singh",
      designation: "DGM - Technology | Burger King India",
      stars: 5,
      image: ReviewImg2,
      reviewText:
        "What would have taken our Product and external engineering team 6+ months to build was done within 3 weeks.",
    },
    {
      clientName: "Tuck Wai Yue",
      designation: "APAC Digital Director | McDonald’s",
      stars: 3,
      image: ReviewImg1,
      reviewText:
        "It was a pleasure to work with ∂etlaBiT. The level of ownership from start to finish was tremendous.",
    },
    {
      clientName: "Eli Kariv",
      designation: "Lead Product Manager | Quizlet",
      stars: 3,
      image: ReviewImg2,
      reviewText:
        "The team delivered a very highly engaging product within the stipulated timelines. The conduct has been solution oriented.",
    },
  ];

  return (
    <section className={styles.reviewsSectionContainer}>
      <h3>Our Partners Swear By Us</h3>

      <img src={Star1} alt="Star1Img" className={styles.star1Img} />
      <img src={Star2} alt="Star2Img" className={styles.star2Img} />
      <img src={Star3} alt="Star3Img" className={styles.star3Img} />
      <img src={Star3} alt="Star3Img" className={styles.star4Img} />

      <img src={Profile1} alt="Profile1Img" className={styles.profile1Img} />
      <img src={Profile2} alt="Profile2Img" className={styles.profile2Img} />
      <img src={Profile3} alt="Profile3Img" className={styles.profile3Img} />
      <img src={Profile4} alt="Profile4Img" className={styles.profile4Img} />
      <img src={Profile5} alt="Profile5Img" className={styles.profile5Img} />
      <img src={Profile6} alt="Profile6Img" className={styles.profile6Img} />
      <img src={Profile5} alt="Profile5Img" className={styles.profile7Img} />

      <div className={styles.reviewSlider}>
        <Slider {...settings}>
          {reviewsData.map((review, index) => (
            <div key={index}>
              <div className={styles.reviewClientContainer}>
                <img width="100px" src={review.image} alt="ReviewImg" />
                <h5>{review.clientName}</h5>
                <span>{review.designation}</span>
                <div className={styles.starContainer}>
                  {Array.from({ length: review.stars }, (_, i) => (
                    <svg
                      key={i}
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 1024 1024"
                    >
                      <path
                        d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5c-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1l-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2c17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9l183.7-179.1c5-4.9 8.3-11.3 9.3-18.3c2.7-17.5-9.5-33.7-27-36.3z"
                        fill="orange"
                      />
                    </svg>
                  ))}
                </div>
              </div>

              <p>{review.reviewText}</p>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ReviewsSection;

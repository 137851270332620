import React, { useState } from "react";
import Modal from "react-modal";
import styles from "./contactus.module.css";
import StarImg from "../../../svgs/stars/3.svg";
import ColoredCurvyWaves from "../../../svgs/coloredCurvyWaves.svg";
import FireWorks from "../../../svgs/fireWorks2.svg";
import AllShapesAttached from "../../../svgs/allShapesAttached.svg";
const ContactModal = ({ isOpen, onRequestClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [helpText, setHelpText] = useState("");

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.9)",
    },
    content: {
      backgroundColor: "transparent",
      border: "none",
    },
  };

  const resetState = () => {
    setName("");
    setEmail("");
    setCompany("");
    setHelpText("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formBody = {
      form_name: "delta_bit_landing_page_contact_us",
      form_data: {
        Name: name,
        Email: email,
        Company: company,
        "How Can We Help": helpText,
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/spreadsheet/submit-form`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formBody),
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully!");
        window.alert("Form submitted successfully!");
      } else {
        console.error("Failed to submit form:", response.statusText);
        window.alert("Failed to submit form. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error.message);
      window.alert("Error submitting form. Please try again.");
    }

    resetState();
    onRequestClose();
  };
  const handleRequestClose = () => {
    resetState();
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      contentLabel="Contact Modal"
      style={customStyles}
      className={styles.modal}
    >
      <form onSubmit={handleSubmit}>
        <div className={styles.mainSection}>
          <div className={styles.mainSectionContainer}>
            <button className={styles.closeButton} onClick={handleRequestClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <g stroke-width="1.5" fill="none">
                  <path
                    d="M6.758 17.243L12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </button>
            <img src={StarImg} alt="StarImg" className={styles.starImg1} />
            <img src={StarImg} alt="StarImg" className={styles.starImg2} />
            <img
              src={ColoredCurvyWaves}
              alt="ColoredCurvyWavesImg"
              className={styles.coloredCurvyWaves}
            />
            <img
              src={FireWorks}
              alt="FireWorksImg"
              className={styles.fireWorks}
            />
            <img
              src={AllShapesAttached}
              alt="AllShapesAttachedImg"
              className={styles.allShapesAttached}
            />
            <h2 className={styles.flexContainer1}>
              Drop Your Details, <br /> We'll Get Back!
            </h2>
            <div className={styles.content}>
              <div className={styles.flexContainer}>
                <label htmlFor="name" className={styles.label}>
                  Name
                </label>
                <div className={styles.inputDiv}>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your Name..."
                    className={styles.inputField}
                    required
                  />
                </div>
              </div>
              <div className={styles.flexContainer}>
                <label htmlFor="email" className={styles.label}>
                  Email
                </label>
                <div className={styles.inputDiv}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your Email..."
                    className={styles.inputField}
                    required
                  />
                </div>
              </div>
              <div className={styles.flexContainer}>
                <label htmlFor="company" className={styles.label}>
                  Company
                </label>
                <div className={styles.inputDiv}>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder="Enter your Company Name..."
                    className={styles.inputField}
                  />
                </div>
              </div>
              <div className={styles.flexContainer}>
                <label htmlFor="helpText" className={styles.label}>
                  How can we help?
                </label>
                <div className={styles.inputDiv}>
                  <input
                    id="helpText"
                    name="helpText"
                    value={helpText}
                    onChange={(e) => setHelpText(e.target.value)}
                    placeholder="Tell us how we can assist you..."
                    className={styles.inputField}
                  />
                </div>
              </div>
            </div>

            <div className={styles.flexContainer1}>
              <button type="submit" className={styles.upperSectionContainer}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ContactModal;

import React from 'react';
import styles from './services.module.css';
import ServicesDescription from './ServicesDescription/ServicesDescription';
import ServicesProfits from './ServicesProfits/ServicesProfits';

const Sevices = () => {
  return <section className={styles.servicesContainer}>
    <ServicesDescription />
    <ServicesProfits />
  </section>;
};

export default Sevices;

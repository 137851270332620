import React from 'react';
import styles from './careerCard.module.css';

const CareerCard = ({ category, categoryColor, position, location }) => {
  return <div className={styles.careerCardContainer}>
    <div className={styles.careerCardContentSection}>
      <span style={{ color: categoryColor }} className={styles.careerCardCategory}>{category}</span>
      <h4>{position}</h4>
      <span className={styles.careerCardLocation}>{location}</span>
    </div>
    <button className={styles.careerCardButton}>View Opening</button>
  </div>;
};

export default CareerCard;
